@import "../../_helpers/variables";

.app {
    header {
        padding: 10px 0;
        background: #292F45;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .right {
            display: flex;
            align-items: center;
        }
        .box-btn {
            display: flex;
            align-items: center;
            .drop-lang {
                margin-right: 20px;
                @media only screen and (max-width : 1499px) {
                    margin-right: 10px;
                }
            }
        }
        .informer {
            position: relative;
            padding: 0 0 0 50px;
            margin-left: 20px;
            @media only screen and (max-width : 1819px) {
                padding: 0 0 0 20px;
            }
            @media only screen and (max-width : 1499px) {
                margin-left: 10px;
            }
            &:after {
                display: block;
                content: '';
                top: -10px;
                left: 0;
                bottom: -10px;
                right: -20px;
                background: #2E344B;
                position: absolute;
                z-index: 1;
            }
            >div {
                position: relative;
                z-index: 2;
            }
        }
        .drop-lang {
            span {
                @media only screen and (max-width : $responsive-size-big-laptop-screen) {
                    display: none;
                }
            }
            .dropdown-menu {
                border: 2px solid #3E4458;
                padding: 0 20px 15px;
                border-radius:0 0 35px 35px;
                background: #292F45;
                font-weight: 500;
                font-size: 15px;
                line-height: 18px;
                color: #D1D7EB;
                min-width: 100%;
                @media only screen and (max-width : $responsive-size-big-laptop-screen) {
                    padding: 0 13px 13px;
                    width: 50px;
                }
                &.show {
                    border-top: none;
                }
                .dropdown-item {
                    padding: 14px 0 0;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    min-height: 20px;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 18px;
                    color: #D1D7EB;
                    &:hover {
                        color: #FF7700;
                        background: none;
                    }
                    img {
                        display: block;
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        margin-right: 5px;
                        @media only screen and (max-width : $responsive-size-big-laptop-screen) {
                            margin-right: 0;
                        }
                    }
                    &:first-child {
                        border-top: 1px solid rgba(255, 255, 255, 0.1);
                    }
                }
            }
            &.show {
                .btn-lang {
                    border-bottom: none;
                    border-radius:35px 35px 0 0 ;
                }
            }
            .btn-lang {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                height: 50px;
                font-weight: 500;
                font-size: 15px;
                line-height: 18px;
                color: #D1D7EB;
                padding: 0px 20px;
                border: 2px solid #3E4458;
                border-radius: 35px;
                transition: none;
                background: none;
                box-shadow: none;
                @media only screen and (max-width : $responsive-size-big-laptop-screen) {
                    padding: 0;
                    border-radius: 50%;
                    width: 50px;
                }
                &:hover {
                    color: #FF7700;
                    background: none;
                    box-shadow: none;
                }
                &:focus {
                    box-shadow: none;
                }
                img {
                    display: block;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    margin-right: 5px;
                    @media only screen and (max-width : $responsive-size-big-laptop-screen) {
                        margin-right: 0;
                    }
                }
                span {
                    display: block;
                    text-transform: capitalize;
                    margin-right: 14px;
                    min-width: 28px;
                    text-align: left;
                    @media only screen and (max-width : $responsive-size-big-laptop-screen) {
                        display: none;
                    }
                }
                svg {
                    display: block;
                    @media only screen and (max-width : $responsive-size-big-laptop-screen) {
                        display: none;
                    }
                }
            }
        }
        .logo {
            display: flex;
            @media only screen and (max-width : $responsive-size-stand-mobile) {
                width: 52px;
                height: 33px;
                overflow: hidden;
                position: relative;
                margin-right: 15px;
            }
            svg {
                display: block;
                @media only screen and (max-width : $responsive-size-stand-mobile) {
                    width: auto;
                    height: 33px;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
        .btn-menu {
            display: none;
            @media only screen and (max-width : $responsive-size-desktop) {
                display: flex;
                height: 50px !important;
                margin-right: 10px;
            }
            @media only screen and (max-width : $responsive-size-stand-mobile) {
                width: 50px;
                padding: 0;
                svg {
                    margin-right: 0;
                }
                span {
                    display: none;
                }
            }
        }
        .nav {
            padding: 0 4.7%;
            flex-grow: 2;
            @media only screen and (max-width : 1599px) {
                padding: 0 2rem;
            }
            @media only screen and (max-width : $responsive-size-laptop-screen) {
                width: 280px;
                position: fixed !important;
                top: 0;
                right: -280px;
                height: 100%;
                background: #292F45;
                flex-direction: column;
                padding-top: 110px;
                overflow-y: scroll;
                align-items: flex-end;
                transition: all linear 0.2s;
                z-index: 9999;
                &.active {
                    right: 0;
                }
            }
            .btn-close {
                position: absolute;
                top: 20px;
                right: 40px;
                display: none;
                @media only screen and (max-width : $responsive-size-laptop-screen) {
                    display: flex;
                }
            }
        }
        .menu {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            @media only screen and (max-width : $responsive-size-desktop) {
                flex-direction: column;
                padding:0 40px;
            }
            li {
                @media only screen and (max-width : $responsive-size-desktop) {
                    border-top: 1px solid rgba(255, 255, 255, 0.1);
                    &:first-child {
                        border-top: none;
                        a {
                            padding-top: 0;
                        }
                    }
                    &:last-child {
                        a {
                            padding-bottom: 0;
                        }
                    }
                }
                a {
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 18px;
                    text-align: center;
                    color: #D1D7EB;
                    @media only screen and (max-width : $responsive-size-desktop) {
                        padding:30px 0;
                        justify-content: flex-end;
                    }
                    svg {
                        display: block;
                        margin-right: 10px;
                    }
                }
            }
        }
        .profile-bar {
            display: none !important;
            @media only screen and (max-width : $responsive-size-laptop-screen) {
                display: flex !important;
                position: relative;
                z-index: 2;
            }
        }
        .user-info {
            display: flex;
            align-items: center;
            @media only screen and (max-width : $responsive-size-laptop-screen) {
                width: 280px;
                position: fixed !important;
                top: 0;
                right: -280px;
                height: 100%;
                background: #292F45;
                flex-direction: column;
                padding-top: 110px;
                overflow-y: scroll;
                align-items: flex-end;
                transition: all linear 0.2s;
                z-index: 9999;
                &.active {
                    right: 0;
                }
            }
            .btn-close {
                position: absolute;
                top: 20px;
                right: 20px;
                display: none;
                @media only screen and (max-width : $responsive-size-laptop-screen) {
                    display: flex;
                }
            }
            .number-profile {
                display: block;
                text-decoration: none;
                font-weight: 500;
                font-size: 15px;
                line-height: 18px;
                text-align: center;
                color: #D1D7EB;
                margin-right: 10px;
            }
            .user {
                display: flex;
                align-items: center;
                @media only screen and (max-width : $responsive-size-laptop-screen) {
                    justify-content: flex-end;
                    padding: 15px 20px 15px 5px;
                    background: #2E344B;
                    width: 100%;
                    margin-bottom: 40px;
                }
            }
            .btn-user {
                display: flex;
                align-items: center;
                @media only screen and (max-width : $responsive-size-laptop-screen) {
                    flex-direction: column;
                    width: 218px;
                    padding-right: 20px;
                    z-index: 9999;
                }
                hr {
                    display: none;
                    @media only screen and (max-width : $responsive-size-laptop-screen) {
                        display: block;
                        height: 1px;
                        border: none;
                        background: rgba(255, 255, 255, 0.1);
                        margin: 30px 0;
                        width: 100%;
                    }
                }
                .btn {
                    margin-left: 5px;
                    @media only screen and (max-width : $responsive-size-laptop-screen) {
                        display: flex;
                        justify-content: flex-end;
                        margin-left: 0;
                        border-radius: 0;
                        background: none;
                        width: 100%;
                    }
                    span {
                        display: none;
                        margin-left: 10px;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 18px;
                        color: #D1D7EB;
                        @media only screen and (max-width : $responsive-size-laptop-screen) {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}