@import "../../_helpers/variables";
body, .app {
  .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 10px;
    border: none;
    background: transparent;
    min-height: 50px;
    padding: 10px 50px;
    transition: all linear 0.2s;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
    outline: none !important;

    &:focus {
      box-shadow: none;
    }
    svg {
      display: block;
      margin-right: 10px;
      transition: all linear 0.2s;
    }

    &.btn-default {
      border: none;
      background: rgba(255, 255, 255, 0.1);
      color: #9EABD3;

      &:focus {
        outline: none !important;
        box-shadow: none !important;
      }

      &:hover {
        color: #FAE529;

        svg {
          path {
            fill: #FAE529;
          }
        }
      }
    }

    &.btn-primary {
      border: none;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      height: 60px;
      background: linear-gradient(248.3deg, #FF8820 5.21%, #FF386A 100%);
      box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.25);
      border-radius: 35px;

      &:hover {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(248.3deg, #FF8820 5.21%, #FF386A 100%);
        box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.25);
      }
      &.btn-connect {
        @media only screen and (max-width : $responsive-size-stand-mobile) {
          font-weight: 700;
          font-size: 13px;
          line-height: 16px;
          text-align: center;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          color: #FFFFFF;
          padding: 0 20px;
        }
        @media only screen and (max-width : $responsive-size-mobile) {
          width: 110px;
        }
        svg {
          @media only screen and (max-width : $responsive-size-stand-mobile) {
            display: none;
          }
        }
      }
    }
    &.btn-secondary {
      border: none;
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      height: 60px;
      background: linear-gradient(85.1deg, #22A164 0%, #6DECAF 100%);
      box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.25);
      border-radius: 35px;

      &:hover {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(85.1deg, #22A164 0%, #6DECAF 100%);
        box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.25);
      }
    }
    &.btn-theme {
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      border: 2px solid #3E4458;
      border-radius: 35px;
      svg {
        display: block;
        margin: 0;
        &.moon {
          path {
            stroke:#848CA9;
          }
        }
        &.sun {
          circle,
          path {
            stroke:#848CA9;
          }
        }
      }
      &:hover {
        svg {
          &.moon {
            path {
              stroke:url(#gradient);
            }
          }
          &.sun {
            circle {
              stroke:url(#paint0_linear_116_973);
            }
            path {
              &.st2 {
                stroke:url(#paint1_linear_116_973);
              }
              &.st3 {
                stroke:url(#paint2_linear_116_973);
              }
              &.st4 {
                stroke:url(#paint3_linear_116_973);
              }
              &.st5 {
                stroke:url(#paint4_linear_116_973);
              }
            }
          }
        }
      }
    }
    &.btn-close {
      width: 50px;
      height: 50px;
      border: 2px solid #3E4458;
      border-radius: 35px;
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      svg {
        display: block;
        margin-right: 0;
      }
      &:hover,
      &:active {
        border: 2px solid #9096AA;
        svg {
          path {
            fill:#9096AA;
          }
        }
      }
    }
    &.btn-profile {
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      border: 2px solid #3E4458;
      border-radius: 35px;
      svg {
        display: block;
        margin-right: 0;
      }
    }
    &.btn-ico {
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      border-radius: 35px;
      background: rgba(255, 255, 255, 0.1);
      svg {
        display: block;
        margin-right: 0;
      }
      &.btn-info {
        svg {
          circle,
          path {
            stroke:#848CA9;
          }
        }
        &:hover {
          svg {
            circle {
              stroke:url(#paint0_linear_116_949);
            }
            path {
              &.st1 {
                stroke:url(#paint1_linear_116_949);
              }
              &.st2 {
                stroke:url(#paint2_linear_116_949);
              }
            }
          }
        }

        @media only screen and (max-width : $responsive-size-laptop-screen) {
          svg {
            circle {
              stroke:url(#paint0_linear_116_949);
            }
            path {
              &.st1 {
                stroke:url(#paint1_linear_116_949);
              }
              &.st2 {
                stroke:url(#paint2_linear_116_949);
              }
            }
          }
        }
      }
      &.btn-logout {
        svg {
          path {
            path {
              fill:#848CA9;
            }
          }
        }
        &:hover {
          svg {
            path {
              fill:url(#paint0_linear_116_955);
            }
          }
        }

        @media only screen and (max-width : $responsive-size-laptop-screen) {
          svg {
            path {
              fill:url(#paint0_linear_116_955);
            }
          }
        }
      }
    }

  }
}
